import React, { useEffect } from "react";
import cx from "classnames";
import Photo from "@components/photo";
import { useAnimation, m } from "framer-motion";
import useInView from "react-cool-inview";
const generateKey = (pre) => {
  return `${pre}_${new Date().getTime()}`;
};
const Phrase = ({
  phrase,
  phraseMaxWidth = "none",
  typeStyle = false,
  wordStyle = false,
  dividerMaxWidth = false,
  verticalEndHeight = 0,
  color = "#000000",
  vertical = false,
  hideLast = false,
  sectionHeightRef = false,
  customThreshold = false,
  delay = 0,
  duration = 2,
  ease = "easeInOut",
  reverse = false,
  isStatic = false,
  noAnim = false,
}) => {
  const wordVariants = {
    visible: {
      opacity: 1,
      transition: { duration: 1, ease: "easeInOut" },
    },
    hidden: { opacity: 1 },
  };

  const horizontalLineVariants = !reverse
    ? {
        visible: {
          opacity: 1,
          maxWidth: dividerMaxWidth ? dividerMaxWidth : "100px",
          transition: {
            duration: duration,
            delay: delay,
            ease: ease,
          },
        },
        hidden: { opacity: 1, maxWidth: 0 },
      }
    : {
        hidden: {
          opacity: 1,
          maxWidth: dividerMaxWidth ? dividerMaxWidth : "100px",
        },
        visible: {
          opacity: 1,
          maxWidth: 0,
          transition: {
            duration: duration,
            delay: delay,
            ease: ease,
          },
        },
      };
  const verticalLineVariants = {
    visible: {
      opacity: 1,
      maxHeight: verticalEndHeight,
      transition: { duration: duration, delay: delay, ease: ease },
    },
    hidden: {
      opacity: 1,
      maxHeight: dividerMaxWidth ? dividerMaxWidth : "1000px",
    },
  };
  const verticalLineVariantsLast = {
    visible: {
      opacity: 1,
      maxHeight: "175px",
      transition: { duration: 3, delay: delay, ease: ease },
    },
    hidden: {
      opacity: 1,
      maxHeight: dividerMaxWidth ? dividerMaxWidth : "1000px",
    },
  };
  const controls = useAnimation();
  const { observe, unobserve, inView, scrollDirection, entry } = useInView(
    {
      rootMargin: "-100px 0px",
      threshold: customThreshold ? customThreshold : [0.2, 0.4, 0.6],
      onEnter: ({ scrollDirection }) => {
        if (scrollDirection.vertical == "up" && !isStatic) {
          controls.start("visible");
        }
      },
      // onLeave: ({ scrollDirection }) => {
      //   if (scrollDirection.vertical == "down") {
      //     controls.start("hidden");
      //   }
      // },
    },
    [controls]
  );

  if (inView && !isStatic) {
    controls.start("visible");
  }

  if (!phrase) return null;
  const phraseWords = phrase.split("-");
  return (
    <div
      className={cx(
        "phrase",
        "flex",
        "w-full",
        "mx-auto",
        "justify-center",
        "items-center",
        "relative",
        typeStyle,
        {
          "flex-col": vertical,
          "max-w-[96vw]": phraseMaxWidth == "none",
          "pb-[50px] mb-[8px]": isStatic,
        }
      )}
      style={{
        maxWidth: phraseMaxWidth !== "none" ? phraseMaxWidth : "none",
      }}
    >
      <div
        ref={observe}
        className={cx("absolute", "top-0", "w-full", "mb-20", {
          "h-screen": sectionHeightRef,
        })}
      ></div>

      {isStatic && (
        <div
          className={cx(
            "w-[1px]",
            "h-[100%]",
            "bg-black",
            "absolute",
            "z-10",
            "top-0",
            "fake-line"
          )}
        ></div>
      )}

      {phraseWords.map((word, idx) => {
        return (
          <>
            <m.div
              key={`${idx}-${word.trim()}`}
              variants={wordVariants}
              animate={controls}
              initial="hidden"
              className={cx(
                "inline-flex",
                "items-center",
                "px-gutter",
                "relative",
                {
                  "flex-col md:pb-0": vertical,
                  "sticky sticky-phrase z-20": isStatic,
                  "top-[30vh]": isStatic && idx == 0,
                  "pb-[44px] md:pb-[70px]":
                    isStatic && idx == 0 && phraseWords.length > 1,
                  "top-[calc(30vh+44px)] md:top-[calc(30vh+70px)]":
                    isStatic && idx == 1,
                }
              )}
            >
              {word.trim().length > 0 && idx == 0 && isStatic && (
                <div
                  className={cx(
                    "absolute",
                    "backdrop",
                    "outline-0",
                    "bottom-[0px]",
                    "left-0",
                    "w-full",
                    "h-[800px]",
                    "pointer-events-none",
                    "flex",
                    {
                      "bottom-[58px]":
                        isStatic && idx == 0 && phraseWords.length > 1,
                    }
                  )}
                ></div>
              )}
              {word.trim().length > 0 && (
                <span
                  className={cx("block", {
                    "md:whitespace-nowrap": !vertical,
                    "pt-0 relative z-10 pb-0 mb-0 mt-full": isStatic,
                    "md:mb-0": !isStatic && idx == 0 && phraseWords.length == 2,
                    "mb-[0px]": isStatic && idx == 0 && phraseWords.length == 2,
                    "mb-[8px] md:mb-0":
                      !isStatic &&
                      idx == 0 &&
                      phraseWords.length == 2 &&
                      vertical,
                  })}
                >
                  <span
                    className={cx(wordStyle, {
                      "bg pb-0 block relative mb-0 overflow-hidden": isStatic,
                    })}
                  >
                    {word.trim()}
                  </span>
                </span>
              )}
            </m.div>

            {idx !== phraseWords.length - 1 && (
              <m.div
                key={`${idx}-line`}
                animate={controls}
                initial={noAnim ? "visible" : "hidden"}
                variants={
                  !vertical
                    ? horizontalLineVariants
                    : idx == 0 && word.trim() == ""
                    ? verticalLineVariantsLast
                    : verticalLineVariants
                }
                className={cx("divider", {
                  "w-[2000px] h-[1px]": !vertical,
                  "h-[250px] md:h-[500px] w-[1px]": vertical,
                  "mt-[-70px]": isStatic && idx == 0 && phraseWords.length > 1,
                })}
                style={{
                  backgroundColor: color,
                }}
              ></m.div>
            )}
          </>
        );
      })}
      {vertical && !hideLast && phrase !== "-" && (
        <m.div
          key={`final-${generateKey("line")}`}
          animate={controls}
          initial="hidden"
          variants={
            !vertical ? horizontalLineVariants : verticalLineVariantsLast
          }
          className={cx("divider", "mb-gutter", {
            "w-[1000px] h-[1px]": !vertical,
            "h-[250px] md:h-[500px] w-[1px]": vertical,
          })}
          style={{
            backgroundColor: color,
          }}
        ></m.div>
      )}
    </div>
  );
};

export default Phrase;
