import React, { useState, useEffect } from "react";
import useInView from "react-cool-inview";
import cx from "classnames";

import { buildSrcSet, buildSrc } from "@lib/helpers";

const Photo = ({
  photo,
  width,
  height,
  srcSizes = [
    400, 600, 800, 1000, 1200, 1600, 1800, 2000, 2200, 2400, 2600, 2800,
  ],
  sizes = "(min-width: 940px) 100vw, 100vw",
  layout = "intrinsic",
  quality = 100,
  hasPlaceholder = true,
  forceLoad,
  onLoad,
  className,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { observe, inView } = useInView({
    unobserveOnEnter: true,
    threshold: 0,
    rootMargin: "500px",
  });
  // trigger any onLoad callbacks
  useEffect(() => {
    if (isLoaded || forceLoad) onLoad?.();
  }, [isLoaded, forceLoad, onLoad]);

  if (!photo?.asset) return null;

  // define our aspect ratio if not a background fill
  const aspect =
    typeof width === "number" && typeof height === "number"
      ? (height / width) * 100
      : 100 / (photo.customRatio || photo.aspectRatio);

  const aspectCustom =
    layout === "intrinsic" || layout === "static"
      ? { paddingTop: `${aspect}%` }
      : null;

  // define our src and srcset
  const src = buildSrc(photo, {
    ...{ width },
    ...{ height },
    ...{ quality },
  });

  const srcset = buildSrcSet(photo, {
    ...{ srcSizes },
    ...{ aspect },
    ...{ quality },
  });

  // handle our image onLoad
  function handleLoad() {
    requestAnimationFrame(() => {
      setIsLoaded(true);
    });
  }

  return (
    <figure className={className ? className : null}>
      <div
        className={cx("ar", {
          "has-fill": layout === "fill" || layout === "contain",
          "mix-blend-multiply transform-gpu relative z-[1]":
            photo.mixBlendMode === "multiply",
        })}
        style={aspectCustom}
      >
        <picture>
          <img
            ref={observe}
            width={width}
            height={height}
            src={forceLoad || inView ? src : null}
            srcSet={forceLoad || inView ? srcset : null}
            sizes={sizes}
            decoding="async"
            onLoad={handleLoad}
            alt={photo.alt || photo.asset?.altText}
            className={cx(getSize(layout), {
              "is-loaded": isLoaded,
            })}
            style={{
              objectPosition: photo.hotspot
                ? `${photo.hotspot.x * 100}% ${photo.hotspot.y * 100}%`
                : null,
            }}
          />
        </picture>
        {hasPlaceholder && (
          <div className={cx("ar--placeholder", { "is-loaded": isLoaded })}>
            <img src={photo.lqip} alt="" role="presentation" />
          </div>
        )}
      </div>
    </figure>
  );
};

const getSize = (layout) => {
  switch (layout) {
    case "static":
      return "static";
    case "intrinsic":
      return "object-cover";
    case "fill":
      return "object-cover";
    case "contain":
      return "object-contain";
  }
};

export default Photo;
